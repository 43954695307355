import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";
import Icon from "../../components/Icons/icon";

const RetirementDueDisability = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Aposentadoria por Invalidez ou Auxílio por Incapacidade de Permanente"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          {
            page: "/aposentadoria-por-invalidez-ou-auxilio-por-incapacidade",
            name: "Aposentadoria por Invalidez ou Auxílio por Incapacidade de Permanente",
          },
        ]}
      >
        {isDesktop && <Aside withContact />}

        <P.Article>
          <p>
            É o benefício pago ao trabalhador permanentemente incapaz de exercer
            qualquer atividade laborativa e que também não possa ser reabilitado
            em outra função.
          </p>
          <p>
            O benefício é pago a qualquer cidadão que tenha vertido
            contribuições ao INSS ou que trabalhe em caráter especial (rural,
            etc.), que não esteja mais em condições de exercer nenhuma função
            que lhe mantenha o sustento.
          </p>
          <p>
            Para que o beneficiário tenha direito ao benefício, primeiro deve
            ser exposto a um perito médico que o avaliará e que o encaminhará
            para a aposentadoria, caso entenda ser este o caso.
          </p>
          <p>
            Destaca-se que para se ter direito é necessário que o requerente
            possuía pelo menos 12 meses de contribuições feitas ao órgão.
          </p>
          <p>
            A carência não será exigida em casos onde a incapacidade se der por
            fato imprevisível: acidente de qualquer natureza, acidente de
            trabalho, doença causada pelo exercício da profissão ou doença grave
            elencadas na lei.
          </p>
          <p>
            Não tem direito à aposentadoria por invalidez quem, ao se filiar à
            Previdência Social, já for portador da doença ou lesão que geraria o
            benefício, a não ser quando a enfermidade agravar durante o período
            em que o requerente realiza as contribuições.
          </p>

          <p>
            Doenças graves que possibilitam a aposentadoria sem o requisito da
            carência:
          </p>

          <P.List>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Tuberculose ativa;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Hanseníase;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Alienação mental;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Esclerose múltipla;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Hepatopatia grave;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Neoplasia maligna;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Cegueira ou visão monocular;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Paralisia irreversível e incapacitante;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Cardiopatia grave;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Doença de Parkinson;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Espondiloartrose anquilosante;</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                Nefropatia grave, estado avançado da doença de Paget (osteíte
                deformante);
              </span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Síndrome da Deficiência Imunológica Adquirida (AIDS);</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                Contaminação por radiação, com base em conclusão da medicina
                especializada;
              </span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Acidente vascular encefálico (agudo);</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Abdome agudo cirúrgico.</span>
            </li>
          </P.List>

          <p>
            Não obstante as informações citadas, destaca-se ainda um adicional
            de 25% que também poderá ser pago ao aposentado por invalidez,
            todavia para se ter direito ao adicional, o beneficiário deve
            comprovar a necessidade de ajuda de terceiras pessoas no seu
            dia-dia, ajuda que se for retirada impedirá o autor de realizar suas
            atividades normais de sobrevivência como tomar banho e comer.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Aposentadoria por Invalidez ou Auxílio por Incapacidade de Permanente"
    description="Assessoria jurídica em Aposentadoria por Invalidez e Auxílio por Incapacidade Permanente. Garantimos suporte para assegurar seus direitos a benefícios, com orientação clara e atendimento confiável."
  />
);

export default RetirementDueDisability;
